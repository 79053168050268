const ScrollData = require('scroll-data');

//pinned nav functionality
const mainNavDiv = document.getElementById("main-nav");
let wait = false;

function scrollNavUpdate () {
  
  if (wait) return;
  let scroll_data = {};
  scroll_data = ScrollData('vertical');
  const navOffset = 200;
  const scrollSpeedTrigger = 10;
  let mainNavClasses = mainNavDiv.classList;
  let windowY = window.scrollY;
  //console.log(scroll_data);

  if (scroll_data['direction'] === 'down' && windowY >= navOffset) {
    mainNavClasses.add('unpinned');
    mainNavClasses.remove("headroom--top");
  } else if (windowY < navOffset) {
    mainNavClasses.remove("unpinned");
    mainNavClasses.add("headroom--top");
  } 
  
  
  // throttling so we don't continually check on all scrolling
  wait = true;
  setTimeout(function (event) {
    wait = false;
  }, 20);

}

document.addEventListener("DOMContentLoaded", () => {
  mainNavDiv.classList.add("headroom");
});
document.addEventListener("scroll", scrollNavUpdate);